section.page-section {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.loading-screen {
  position: fixed !important;
  background-color: black;
  z-index: 10;
}

.loading-screen div {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

body {
  font-family: "Poppins", sans-serif !important;
}

.btn {
  border-radius: 9px;
}

.section-header {
  /* border-bottom: 3px solid #ea3960; */
  display: inline-block;
  overflow: hidden;
  /* padding-right: 0.2rem; */
}

.underline {
  height: 3px;
  background-color: #ea3960;
  width: 100%;
}

.description {
  color: #5a5a5a;
}

.footer {
  background-color: black;
}

.footer .social-icons {
  cursor: pointer;
}

.footer .social-icons :hover {
  color: #ea3960 !important;
}

.project-slick:focus {
  outline: none;
}
.mouse-point {
  cursor: pointer;
  color: black;
}

.mouse-point:hover {
  color: #ea3960 !important;
}

.form-control {
  border-radius: 0.3rem !important;
  border: 1px solid #dedede !important;
}

.form-control::-moz-placeholder {
  color: #b7b7b7;
}
.form-control:-ms-input-placeholder {
  color: #b7b7b7;
}
.form-control::-webkit-input-placeholder {
  color: #b7b7b7;
}
